// @flow
import React, { useState, useEffect } from 'react'
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { pink, deepOrange } from '@material-ui/core/colors'
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Link,
  IconButton,
  useMediaQuery,
  Drawer,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core'
import Request from '../helpers/Request'
import AccountMenu from './AccountMenu'
import Footer from './Footer'
import clsx from "clsx"
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Home as HomeIcon,
  Menu as MenuIcon,
  Euro as EuroIcon,
  Phone as PhoneIcon,
  FeaturedPlayList as FeaturedPlayListIcon,
} from "@material-ui/icons"

const theme = createMuiTheme({
  palette: {
    primary: pink,
    secondary: deepOrange,
  },
})

const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#4F46BA',
    },
    secondary: {
      main: '#FFD850',
    },
  },
})

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // marginTop: 64,
    // backgroundColor: 'red',
    // minHeight: 'calc(100vh - 64px)',
    minHeight: '100vh',
    position: 'relative',
    // paddingBottom: 100,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // background: 'transparent',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    // minWidth: 140,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(0) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  // content: {
  //   // flexGrow: 1,
  //   // padding: theme.spacing(3),
  //   // width: `calc(100% - ${drawerWidth}px)`
  // },
  // '@media (max-width: 599px)': {
  //   root: {
  //     // marginTop: 56,
  //     // minHeight: 'calc(100vh - 56px)',
  //     minHeight: '100vh',
  //     // paddingBottom: 140,
  //   }
  // },
  // '@media (max-height: 600px)': {
  //   root: {
  //     minHeight: 600,
  //   }
  // },
  // '@media (max-width: 599px) and (max-height: 600px)': {
  //   root: {
  //     // marginTop: 56,
  //     // minHeight: 600,
  //     // paddingBottom: 140,
  //   }
  // },
}))

const pages = [
  {
    name: 'Home',
    href: '/',
    icon: <HomeIcon />,
  },{
    name: 'Features',
    href: '/features',
    icon: <FeaturedPlayListIcon />,
  },
  {
    name: 'Pricing',
    href: '/pricing',
    icon: <EuroIcon />,
  },
  {
    name: 'Contact Us',
    href: '/contact_us',
    icon: <PhoneIcon />
  }
]

const StaticWrapper = props => {
  const classes = useStyles()
  // const matchesMD = useMediaQuery('(min-width: 600px)')
  const matchesMD = useMediaQuery('(min-width: 733px)')
  const [auth, setAuth] = useState(App.Auth)
  const [open, setOpen] = useState(false)
  const [scrolledDown, setScrolledDown] = useState(false)

  useEffect(() => {
    if (['/users/password/edit', '/users/password/new', '/users/sign_in', '/users/sign_up', '/terms_of_use', '/privacy_policy'].includes(window.location.pathname)) {
      setScrolledDown(true)
    } else {
      window.onscroll = onWindowScroll
    }
  }, [])

  const onWindowScroll = () => {
    setScrolledDown(document.documentElement.scrollTop !== 0)
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <ThemeProvider theme={App.admin_control ? theme : defaultTheme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          style={{
            backgroundColor: scrolledDown ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
            transition: 'background-color 1s ease-in-out',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: matchesMD || open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              className={clsx({[classes.title]: !matchesMD})}

            >
              <Link
                href='/'
                color="inherit"
                underline='none'
                style={{
                  fontWeight: 400,
                }}
              >
                {/*ERAFLEET*/}
                Erafleet
              </Link>
            </Typography>
            {
              matchesMD &&
              <Box
                style={{
                  flexGrow: 1,
                  paddingLeft: 20,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {
                  pages.map(page => (
                    <Button
                      key={page.name}
                      href={page.href}
                      style={{
                        color: 'white',
                        textTransform: 'none',
                        fontFamily: 'Manrope',
                        marginLeft: 15,
                        marginRight: 15,
                      }}
                    >
                      {page.name}
                    </Button>
                  ))
                }
              </Box>
            }
            {auth ? (
              <AccountMenu />
            ) : (
              <div
                style={{
                  textAlign: 'right'
                }}
              >
                <Button
                  color='inherit'
                  href='/users/sign_in'
                  style={{
                    textTransform: 'none',
                    fontFamily: 'Manrope',
                  }}
                >
                  Sign In
                </Button>
                <Button
                  color='secondary'
                  variant='contained'
                  href='/users/sign_up'
                  style={{
                    textTransform: 'none',
                    fontFamily: 'Manrope',
                    marginLeft: 10,
                  }}
                >
                  Start Free
                </Button>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          anchor='left'
          open={open}
          onClose={toggleDrawer}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {
              pages.map(page => (
                <ListItem
                  button
                  key={page.name}
                  component='a'
                  href={page.href}
                >
                  <ListItemIcon>{page.icon}</ListItemIcon>
                  <ListItemText primary={page.name} />
                </ListItem>
              ))
            }
          </List>
        </Drawer>
        <main className={classes.content}>
          {props.children}
        </main>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default StaticWrapper
