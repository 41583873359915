// @flow
import React from 'react'

export const localFormatDate = (rawDate, returnOnly = undefined) => {
  const fullDate = new Date(rawDate)

  const y = fullDate.getFullYear()
  const m = fullDate.getMonth() + 1
  const d = fullDate.getDate()
  const date = `${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}`

  const h = fullDate.getHours()
  const mins = fullDate.getMinutes()
  const s = fullDate.getSeconds()
  const time = `${h <= 9 ? `0${h}` : h}:${mins <= 9 ? `0${mins}` : mins}:${
    s <= 9 ? `0${s}` : s
  }`

  if (returnOnly == 'date') {
    return date
  }

  if (returnOnly == 'time') {
    return time
  }
  
  return `${date} ${time}`
}

export const utcFormatDate = (rawDate) => {
  const fullDate = new Date(rawDate)

  const y = fullDate.getUTCFullYear()
  const m = fullDate.getUTCMonth() + 1
  const d = fullDate.getUTCDate()
  const date = `${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}`

  const h = fullDate.getUTCHours()
  const mins = fullDate.getUTCMinutes()
  const s = fullDate.getUTCSeconds()
  const time = `${h <= 9 ? `0${h}` : h}:${mins <= 9 ? `0${mins}` : mins}:${
    s <= 9 ? `0${s}` : s
  }`
  
  return `${date} ${time}`
}


export const timeDiff = (date1, date2) => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)
  if (d1 < d2) {
      d1.setDate(d1.getDate() + 1);
  }

  const diff = d1 - d2
  let msec = diff;
  const hh = Math.floor(msec / 1000 / 60 / 60);
  msec -= hh * 1000 * 60 * 60;
  const mm = Math.floor(msec / 1000 / 60);
  msec -= mm * 1000 * 60;
  const ss = Math.floor(msec / 1000);
  return [hh, mm, ss]
}

export const measure = (lat1, lon1, lat2, lon2) => {  // generally used geo measurement function
  var R = 6378.137 // Radius of earth in KM
  var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180
  var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180
  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
  Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
  Math.sin(dLon/2) * Math.sin(dLon/2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
  var d = R * c
  return d * 1000 // meters
}

// export const FormatDate = (_date) => {
//   const fullDate = new Date(_date)

//   const y = fullDate.getUTCFullYear()
//   const m = fullDate.getUTCMonth() + 1
//   const d = fullDate.getUTCDate()
//   const date = `${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}`

//   const h = fullDate.getUTCHours()
//   const mins = fullDate.getUTCMinutes()
//   const s = fullDate.getUTCSeconds()
//   const time = `${h <= 9 ? `0${h}` : h}:${mins <= 9 ? `0${mins}` : mins}:${
//     s <= 9 ? `0${s}` : s
//     }`

//   return `${date} ${time} UTC`
// }
// App.FormatDate = FormatDate

export const TimeAgo = (dateParam) => {
  const MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const getFormattedDate = (
    date,
    prefomattedDate = false,
    hideYear = false
  ) => {
    const day = date.getDate()
    const month = MONTH_NAMES[date.getMonth()]
    const year = date.getFullYear()
    let hours = date.getHours()
    let minutes = date.getMinutes()

    if (hours < 10) {
      // Adding leading zero to hours
      hours = `0${hours}`
    }

    if (minutes < 10) {
      // Adding leading zero to minutes
      minutes = `0${minutes}`
    }

    if (prefomattedDate) {
      // Today at 10:20
      // Yesterday at 10:20
      return `${prefomattedDate} at ${hours}:${minutes}`
    }

    if (hideYear) {
      // 10. January at 10:20
      return `${day}. ${month} at ${hours}:${minutes}`
    }

    // 10. January 2017. at 10:20
    return `${day}. ${month} ${year}. at ${hours}:${minutes}`
  }

  if (!dateParam) {
    return null
  }

  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam)
  const DAY_IN_MS = 86400000 // 24 * 60 * 60 * 1000
  const today = new Date()
  const yesterday = new Date(today - DAY_IN_MS)
  const seconds = Math.round((today - date) / 1000)
  const minutes = Math.round(seconds / 60)
  const isToday = today.toDateString() === date.toDateString()
  const isYesterday = yesterday.toDateString() === date.toDateString()
  const isThisYear = today.getFullYear() === date.getFullYear()

  if (seconds < 5) {
    return 'now'
  } else if (seconds < 60) {
    return `${seconds} seconds ago`
  } else if (seconds < 90) {
    return 'about a minute ago'
  } else if (minutes < 60) {
    return `${minutes} minutes ago`
  } else if (isToday) {
    return getFormattedDate(date, 'Today') // Today at 10:20
  } else if (isYesterday) {
    return getFormattedDate(date, 'Yesterday') // Yesterday at 10:20
  } else if (isThisYear) {
    return getFormattedDate(date, false, true) // 10. January at 10:20
  }

  return getFormattedDate(date) // 10. January 2017. at 10:20
}
App.TimeAgo = TimeAgo

export const AnonLink = ({
  url,
  children,
}) => {
  const anonUrl = `https://href.li/?${url}`

  return (
    <a href={anonUrl} rel="noopener noreferrer" target="_blank">
      {children || anonUrl}
    </a>
  )
}

export const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

export const buildFormData = (
  formData,
  data,
  parentKey = null
) => {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    if (typeof data === 'object' && data.originFileObj) {
      // Process antd file object
      formData.append(parentKey, data.originFileObj)
    } else {
      Object.keys(data).forEach(key => {
        let nextParentKey = key

        if (parentKey) {
          if (Array.isArray(data)) {
            // Don't use input[0] instead of input[] for arrays because rails will treat that as Hash not Array
            nextParentKey = `${parentKey}[]`
          } else {
            nextParentKey = `${parentKey}[${key}]`
          }
        }

        buildFormData(formData, data[key], nextParentKey)
      })
    }
  } else {
    const value = data == null ? '' : data

    formData.append(parentKey, value)
  }
}

// Return param from URL. i.e. deriveParam('id') => 61
export const deriveParam = (searchFor, path) => {
  if (!path && window && window.location && window.location.pathname) {
    path = window.location.pathname
  }

  if (!path) {
    return null
  }

  if (typeof searchFor == 'string') {
    switch (searchFor) {
      case 'id': {
        const id = path
          .split('/')
          .reverse()
          .find(p => /^\d+$/.test(p))

        if (id) {
          return parseInt(id)
        }

        return null
      }
    }
  } else if (typeof searchFor == 'number') {
    return path.split('/')[searchFor]
  }

  return null
}
