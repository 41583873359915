// @flow
import React, { useState } from 'react'
import {
  IconButton,
  MenuItem,
  ListItemIcon,
  Menu,
  Link,
  Divider,
  Typography,
} from '@material-ui/core'
import {
  AccountCircle,
  BusinessCenter,
  MeetingRoom,
  CreateNewFolder,
  SupervisorAccount,
} from '@material-ui/icons'
import Request from '../helpers/Request'

const AccountMenu = () => {

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  // $FlowFixMe
  const companies = App && App.companies
  const entity = App && App.current_entity
  const user = App && App.current_user
  const entityType = App && App.current_entity_type

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    return Request(App.admin_control ? '/users/sign_out_from_user' : '/users/sign_out', 'DELETE')
      .then(() => {
        window.location = App.admin_control ? '/admin/dashboard' : '/'
      })
      .catch(() => window.location.reload())
  }

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        {entityType === 'User' ?
          <AccountCircle style={{ marginRight: 10 }} /> :
          <BusinessCenter style={{ marginRight: 10 }} />
        }
        <Typography variant="body2">
          {App.current_entity}
        </Typography>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          style={{ color: '#3f51b5' }}
          href='/account/dashboard'
        >
          <ListItemIcon>
            {entityType == 'User' ?
              <AccountCircle style={{ color: "#3f51b5" }} /> :
              <BusinessCenter style={{ color: "#3f51b5" }} />
            }
          </ListItemIcon>
          <Typography variant="inherit">
            {`${App.current_entity} Dashboard`}
          </Typography>
        </MenuItem>

        <Divider />

        <MenuItem
          component={Link}
          href='/account/companies/delete'
        >
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <Typography variant="inherit">
            {user}
          </Typography>
        </MenuItem>
        {
          (
            () =>
              companies && companies.map(
                (company, i) =>
                  <MenuItem
                    key={`${i}${company.id}`}
                    component={Link}
                    href={`/account/companies/${company.id}`}
                  >
                    <ListItemIcon>
                      <BusinessCenter />
                    </ListItemIcon>
                    <Typography variant="inherit">
                      {company.title}
                    </Typography>
                  </MenuItem>
              )
          )()
        }

        <Divider />

        <MenuItem
          style={{
            fontSize: 14,
            color: 'grey'
          }}
          component={Link}
          href='/account/companies/new'
        >
          <ListItemIcon>
            <CreateNewFolder fontSize='small'/>
          </ListItemIcon>
          <Typography variant="inherit">
            Create Company
          </Typography>
        </MenuItem>

        <Divider />

        {App.admin &&
            <MenuItem
              style={{
                fontSize: 14,
                color: 'red'
              }}
              component={Link}
              href='/admin/dashboard'
            >
              <ListItemIcon>
                <SupervisorAccount
                  fontSize='small'
                  style={{ color: 'red' }}
                />
              </ListItemIcon>
              <Typography variant="inherit">
                Admin Dashboard
              </Typography>
            </MenuItem>
        }
        {App.admin && <Divider />}

        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <MeetingRoom />
          </ListItemIcon>
          <Typography variant="inherit">
            {App.admin_control ? 'Logout From User' : 'Logout'}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default AccountMenu
