import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link, useMediaQuery,
} from '@material-ui/core'

import CityShadow from 'images/city_shadow'

const useStyles = makeStyles(theme => ({
  backgroundImage: {
    backgroundImage: `url(${CityShadow})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'repeat-x',
    backgroundSize: 'auto clamp(80px, 25vw, 162px)',
    paddingBottom: 'clamp(80px, 25vw, 162px)',
  },
  container: {
    maxWidth: 1220,
    margin: 'auto',
    padding: '60px 10px',
    '& > div > div': {
      '& > p:nth-child(1)': {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 20,
        lineHeight: '25px',
        marginBottom: 15,
      },
      '& > p:nth-child(2)': {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '25px',
        color: '#757575',
      },
    },
    '& > div > div:nth-child(1)': {
      '& li': {
        padding: '2px 0 13px',
        '& a': {
          fontFamily: 'Manrope',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '10px',
          color: '#757575',
        },
      },
    },
    '& > div > div:nth-child(2)': {
      '& li': {
        padding: '2px 0 13px',
        '& > div:nth-child(1)': {
          minWidth: 30
        },
        '& > div:nth-child(1) > a': {
          fontFamily: 'Manrope',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '10px',
          color: '#3734A9',
        },
      },
    },
  },
  rights: {
    backgroundColor: 'black',
    '& > p': {
      color: '#F2F3FF',
      textAlign: 'center',
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  '@media (max-width: 640px)': {
    container: {
      textAlign: 'center',
      '& li': {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& p': {
        maxWidth: 200,
        margin: 'auto',
      },
    },
    rights: {
      '& > p': {
        fontSize: 15,
      },
    },
  },
}))

const Footer = () => {
  const classes = useStyles()
  const matchesSM = useMediaQuery('(max-width: 640px)')

  return (
    <div
      id={'footer'}
    >
      <div
        className={classes.backgroundImage}
      >
        <div
          className={classes.container}
        >
          <Grid container>
            <Grid item xs={matchesSM ? 12 : 4}>
              <Typography>
                Menu
              </Typography>
              <List>
                {[
                  {
                    text: 'Home',
                    href: '/'
                  },
                  {
                    text: 'Features',
                    href: '/features'
                  },
                  {
                    text: 'Pricing',
                    href: '/pricing'
                  },
                  {
                    text: 'Terms of use',
                    href: '/terms_of_use'
                  },
                  {
                    text: 'Privacy policy',
                    href: '/privacy_policy'
                  },
                  {
                    text: 'Contact us',
                    href: '/contact_us'
                  },
                ].map((item, i) => (
                  <ListItem
                    key={`${item.text}-${i}`}
                  >
                    <Link
                      href={item.href}
                    >
                      {item.text}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={matchesSM ? 12 : 4}>
              <Typography>
                Quick Contact
              </Typography>
              <Typography>
                If you have any questions or need help, feel free to contact with our team.
              </Typography>
              <List>
                {[
                  // {
                  //   text: '+(370) 05 585',
                  //   href: 'tel:+37067605585',
                  //   image: <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  //     <path d="M5.51587 0.820312C5.73852 0.908854 5.98462 0.964193 6.18384 1.09147C6.57056 1.33496 6.76392 1.70573 6.78149 2.14844C6.81079 2.90658 6.86938 3.65918 7.06274 4.40072C7.13892 4.69954 7.23852 4.99837 7.32056 5.2972C7.4436 5.75651 7.32642 6.16602 7.02173 6.53678C6.51782 7.15104 6.01977 7.77637 5.52759 8.40169C5.49829 8.4349 5.49829 8.5179 5.52173 8.56217C6.5354 10.3939 7.96509 11.8604 9.87524 12.8896C10.0452 12.9837 10.2151 13.0833 10.3967 13.1663C10.4436 13.1885 10.5374 13.1885 10.5784 13.1553C11.2405 12.6904 11.9026 12.2256 12.553 11.7441C12.9397 11.4564 13.3674 11.3734 13.842 11.473C14.3166 11.5781 14.7795 11.7275 15.2541 11.7995C15.8635 11.888 16.4846 11.9378 17.1057 11.971C17.8264 12.0098 18.3538 12.3639 18.5413 12.9893C18.5823 13.1276 18.5998 13.2715 18.5998 13.4154C18.6057 14.4059 18.6057 15.3965 18.5998 16.387C18.5998 17.2393 17.967 17.8258 17.0647 17.8203C13.7366 17.7871 10.678 16.9128 7.92407 15.153C5.25806 13.4541 3.27173 11.2018 1.97681 8.41276C1.34399 7.05143 0.922119 5.6237 0.740479 4.14616C0.676025 3.71452 0.640869 3.27734 0.593994 2.8457C0.593994 2.56901 0.593994 2.29232 0.593994 2.01562C0.693604 1.7998 0.769775 1.56185 0.904541 1.36263C1.12134 1.04167 1.47876 0.90332 1.85962 0.820312C3.07837 0.820312 4.29712 0.820312 5.51587 0.820312Z" fill="#3734A9"/>
                  //   </svg>,
                  // },
                  {
                    text: 'info@erafleet.com',
                    href: 'mailto: info@erafleet.com',
                    image: <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.5947 5.03809C20.5947 8.09857 20.5947 11.1643 20.5947 14.23C20.5895 14.2404 20.5738 14.2508 20.5738 14.2612C20.3082 15.3196 19.6624 15.8254 18.5738 15.8254C13.2561 15.8254 7.93843 15.8254 2.62072 15.8254C2.55822 15.8254 2.49051 15.8254 2.42801 15.8254C1.38114 15.7889 0.605098 14.9964 0.605098 13.9484C0.59989 11.0287 0.605098 8.10899 0.605098 5.18929C0.605098 5.14236 0.615515 5.09544 0.620723 5.03809C0.657181 5.05894 0.672806 5.06415 0.688431 5.07458C3.39156 6.87854 6.08947 8.68251 8.7926 10.4813C10.0061 11.2894 11.2041 11.2894 12.4176 10.4813C14.8343 8.8702 17.2457 7.25915 19.6624 5.6481C19.9645 5.44476 20.2822 5.24142 20.5947 5.03809Z" fill="#3734A9"/>
                      <path d="M20.5934 2.92667C20.5205 3.1248 20.4788 3.34899 20.3642 3.52104C20.2028 3.75045 20.0153 3.99028 19.7861 4.14148C17.0725 5.97151 14.3486 7.7859 11.6194 9.59508C10.9475 10.0435 10.2392 10.0382 9.56213 9.59508C6.83297 7.77547 4.1038 5.95587 1.37984 4.13105C0.723593 3.6931 0.45276 2.9371 0.661093 2.1811C0.874635 1.40947 1.53609 0.882878 2.35901 0.825526C2.42151 0.820312 2.4788 0.820312 2.53609 0.820312C7.90588 0.820312 13.2757 0.820312 18.6507 0.820312C19.5413 0.820312 20.234 1.29477 20.4996 2.08204C20.5361 2.19153 20.5621 2.30624 20.5986 2.41572C20.5934 2.58778 20.5934 2.75462 20.5934 2.92667Z" fill="#3734A9"/>
                    </svg>,
                  },
                ].map((item, i) => (
                  <ListItem
                    key={`${item.text}-${i}`}
                  >
                    <ListItemIcon>
                      {item.image}
                    </ListItemIcon>
                    <ListItemText>
                      <Link
                        href={item.href}
                      >
                        {item.text}
                      </Link>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={matchesSM ? 12 : 4}>
              <Typography>
                Address
              </Typography>
              <Typography>
                Lithuania, Vilnius
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        className={classes.rights}
      >
        <Typography>
          &copy; Erafleet {new Date().getFullYear()}. All rights reserved.
        </Typography>
      </div>
    </div>
  )
}

export default Footer