// @fflow

import { Promise } from 'es6-promise'
import qs from 'qs'
import 'whatwg-fetch'

import { buildFormData } from 'helpers/Helpers'

const dedotizeErrors = errors => {
  let dedotizedErrors = {}

  for (let key in errors) {
    let params = key.split('.')
    let value = errors[key]

    if (Array.isArray(value)) {
      value = value[0]
    }

    for (let i = params.length; i > 0; i--) {
      const oldValue =
        value.constructor === Object ? dedotizeErrors(value) : value

      value = {}
      value[params[i - 1]] = oldValue
    }

    dedotizedErrors = Object.assign(dedotizedErrors, value)
  }

  return dedotizedErrors
}

const Request = async (
  path,
  method,
  options = {}
) => {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  if (!options.disableCsrfHeader) {
    headers['X-CSRF-Token'] = App.authenticityToken
  }

  let fetchData = {}

  if (!method) {
    // eslint-disable-next-line no-console
    console.error(
      `You must specify method for App.Request. Error caught with: '${path}'`
    )
  }

  fetchData.method = method.toUpperCase()
  fetchData.headers = {
    ...headers,
    ...options.headers,
  }

  // Auto generate content type
  // Useful for multipart because boundary must be added
  if (fetchData.headers['Content-Type'] === undefined) {
    delete fetchData.headers['Content-Type']
  }

  if (options.payload) {
    fetchData.body = options.payload
  }

  if (options.body) {
    if (method == 'GET') {
      const queryParams = qs.stringify(options.body)

      path = `${path}?${queryParams}`
    } else {
      // TODO: fetch errors that should have _attributes prefix and inject them prefixed into errors response
      // Both prefixed and non-prefixed should exist to maintain backwards compatability with manually converted ones.

      if (options.convertToFormData) {
        // Content type will be set automatically
        delete fetchData.headers['Content-Type']

        const formData = new FormData()

        buildFormData(formData, options.body)

        fetchData.body = formData
      } else {
        if (fetchData.headers['Content-Type'] == 'application/json') {
          fetchData.body = JSON.stringify(options.body).replace(
            /"(.+)":/g,
            '"$1":'
          )
        } else {
          fetchData.body = options.body
        }
      }
    }
  }

  fetchData.credentials = 'same-origin'

  const response = await fetch(path, fetchData)

  if (
    (response.status >= 200 && response.status < 300) ||
    [401, 422].includes(response.status)
  ) {
    if (response.status == 401 && /\/account.*/.test(path)) {
      // Redirect unauthorized requests to account to sign in page
      window.location = '/users/sign_in'

      return Promise.reject()
    }

    if (response.status == 204) {
      return Promise.resolve()
    }

    switch (fetchData.headers['Accept']) {
      case 'application/json': {
        const responseJSON = await response.json()

        if (response.ok) {
          return Promise.resolve(responseJSON)
        } else {
          if (typeof responseJSON == 'object') {
            if (responseJSON.errors) {
              // Rails returns dotted object path with array of errors.
              // We need normal object path with only value of single error.
              // This will fix that.
              let fixedErrors = dedotizeErrors(responseJSON.errors)

              let fixedReponse = {
                ...response,
                errors: fixedErrors,
              }

              return Promise.reject({
                status: response.status,
                errors: fixedReponse.errors,
              })
            } else if (responseJSON.error) {
              return Promise.reject({
                status: response.status,
                errors: responseJSON.error,
              })
            } else {
              return Promise.reject({
                status: response.status,
                ...responseJSON,
              })
            }
          } else {
            return Promise.reject(responseJSON)
          }
        }
      }
      case 'text/html': {
        const responseText = await response.text()

        return Promise.resolve({
          status: response.status,
          ok: response.ok,
          body: responseText,
        })
      }
      default:
        throw `Accept header ${fetchData.headers['Accept']} is not supported`
    }
  } else if (response.status == 304) {
    const location = response.headers.get('Location')

    if (location) {
      window.location = location

      // message.info(`Redirecting to ${location}`)
    }
  } else {
    if (response.status == 500) {
      // App.Store.dispatch({
      //   type: 'GLOBAL_NOTIFICATION',
      //   kind: 'error',
      //   message:
      //     "We're sorry, but something went wrong. Please try this action later or contact support@erafleet.com",
      // })
      console.log("We're sorry, but something went wrong. Please try this action later or contact support@erafleet.com")
    }

    return Promise.reject(response)
  }
}

// DEPERECATED
App.Request = Request

export default Request
